<script>
const bookingDayService = importVueComp('components/booking', 'DayService', 'custom');
export default {
    name: 'custom-day-service',
    mixins: [bookingDayService],
    data () {
        let record = this.service.$parent.$parent;
        let allServiceIds = [...new Map(
            record.BookingDays.flatMap(day => 
                day.BookingDayServices.map(service => {
                    if (service.ServiceId && this.service.ServiceId != service.ServiceId) 
                        return {value: service.ServiceId, label: service.Service.Name}
                })
            ).filter(r => r).map(item => [item.value, item])
        ).values()];
        
        let f = this.service.fields.find(f => f.name == 'GroupByServiceId');
        let extraFields = [];
        if (f) {
            f.options = allServiceIds;
            extraFields.push(f)
        };
        return {
            extraFields
        }
    }
}
</script>



