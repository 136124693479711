<template>
    <div v-if="recordStore">
        <!--section class="breadcrumbs" :style="image">
          <div class="container">
            <h2>{{recordStore.Title}}</h2>
          </div>
        </section-->
        <itinerary-header-cover></itinerary-header-cover>
        <section id="trip" class="banner">
          <!-- div class="">
            <h2>{{recordStore.Title}}</h2>
          </div-->
          <div class="container">
            <div class="col-lg-12">
              <div class="row gx-4  ">
                <div class="col">
                  <div class="info-box">
                    <i class="ri-user-line"></i>
                    <div>
                      <p>{{tr('TRAVEL FOR')}}</p>
                      <h3>{{recordStore.PaxName}}</h3>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="info-box">
                    <i class="ri-calendar-2-line"></i>
                    <div>
                      <p>{{tr('DURATION')}}</p>
                      <h3>{{recordStore.daysLength}} {{tr('Days')}} /
                      {{recordStore.daysLength - 1}} {{tr('Nights')}}</h3>
                    </div>
                  </div>
                </div>
                <div class="col" v-if="recordStore.TripModeId">
                  <div class="info-box">
                    <i class="ri-map-pin-user-line"></i>
                    <div>
                      <p>{{tr('TRIP MODE')}}</p>
                      <h3>{{tr(recordStore.TripMode.Name)}}</h3>
                    </div>
                  </div>
                </div>
                <div class="col" v-if="recordStore.Difficulty">
                  <div class="info-box">
                    <i class="ri-walk-line"></i>
                    <div>
                      <p>{{tr('DIFFICULTY')}}</p>
                      <h3>{{tr(recordStore.Difficulty)}}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <itinerary-header-summary :canEdit="canEdit">
        </itinerary-header-summary>
        <section id="trip2">
          <div class="container not-break-inside">
            <div class="row">
              <div v-if="!recordStore.CustomerType.Agency" class="col-6-4 mt-4">
                <div class="member">
                  <div class="member-info">
                    <div class="pic">
                      <async-img :url="recordStore.SalesUser.Image.url" class="img-fluid"
                      v-if="recordStore.SalesUser && recordStore.SalesUser.Image && recordStore.SalesUser.Image.url">
                    </async-img>
                  </div>
                    <h4>{{recordStore.SalesUser.FirstName}} {{recordStore.SalesUser.LastName}}</h4>
                    <span>{{getJobTitle}}</span>
                    <div class="social">
                      <a :href="'mailto:' + recordStore.SalesUser.Email"><i class="ri-mail-fill"></i></a>
                      <div class="social-text ml-2">{{recordStore.SalesUser.Email}}</div>
                    </div>
                    <div class="social" v-if="recordStore.SalesUser.Skype">
                      <a href=""><i class="ri-skype-fill"></i></a>
                      <div class="social-text ml-2">{{recordStore.SalesUser.Skype}}</div>
                    </div>
                    <div class="social" v-if="recordStore.SalesUser.Phone">
                      <a href=""><i class="ri-whatsapp-fill"></i></a>
                      <div class="social-text ml-2">{{recordStore.SalesUser.Phone}}</div>
                    </div>
                    <text-rows-style
                        class="text-justify"
                        tag="p"
                        :text="getUserProfile"
                    ></text-rows-style>
                  </div>
                </div>
              </div>
              <div :class="{'col-md-4 col-sm-12 mt-4': !recordStore.CustomerType.Agency, 'col-md-6 col-sm-12 mt-4': recordStore.CustomerType.Agency}">
                <div class="sidebar map">
                  <h3 class="sidebar-title">{{tr('Map')}}</h3>
                  <div v-if="displayMap" id="mapDiv">
                    <google-map
                        :bookingCities="bookingCities"
                        :bookingCitiesObject="recordStore.bookingCitiesObject"
                        :languageCode="recordStore.Language.Code"
                    v-if="bookingCities.length>0"/>
                  </div>
                </div>
              </div>
              <div :class="{'col-md-4 col-sm-12 mt-4': !recordStore.CustomerType.Agency, 'col-md-6 col-sm-12 mt-4': recordStore.CustomerType.Agency}" class=" print-hide">
                <div class="sidebar">
                  <h3 class="sidebar-title">{{tr('ITINERARY')}}</h3>
                  <div class="sidebar-item recent-posts">
                    <div class="post-item clearfix" v-for="day of recordStore.BookingDays" :key="day.DayNr">
                      <div v-if="!recordStore.skipDays[day.DayNr] && toShow(day)">
                        <a class="nav-link cursor-pointer d-flex" @click="allDays = true" v-if="showMoreDays(day)">
                          <i class="dot ri-focus-line"></i>
                          <h4>...</h4>
                        </a>
                        <a class="nav-link cursor-pointer d-flex" @click="scrollTo('day-' + day.DayNr)" v-else
                          @mouseover="overDay(day)" @mouseleave="mouseLeave()">
                          <i class="dot ri-focus-line"></i>
                          <h4>{{getDayNumber(day)}}</h4>
                          <h4 class="ml-2"><i> {{day.dayTitle}}</i></h4>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    </div>
</template>

<script>
const itineraryHeader  = importVueComp('components/itinerary', 'ItineraryHeader', 'custom');
export default {
    name: 'custom-itinerary-header',
    mixins: [itineraryHeader],
    computed: {

    },
    methods: {

    }
}
</script>


