import BookingBreakdown from '@/extra/custom/components/booking/BookingBreakdown';

function getCustomRoutes () {
    let res = [];
    res.push({
        path: '/public/bookingbreakdown/:hash/:id',
        name: 'booking-breakdown',
        component: BookingBreakdown,
        props: true,
        meta: {title: 'NWTD', icon: 'custom_favicon.ico', public: true},
    })
    let remove = []
    return {routes: res, remove: remove};
}

export default getCustomRoutes();