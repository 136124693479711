<script>
const salesByUserReport = importVueComp('components/reports', 'SalesByUserReport', 'custom');
export default {
    name: 'custom-sales-by-user-report',
    mixins: [salesByUserReport],
    methods: {
        getData (self) {
            let r = salesByUserReport.methods.getData(this);
            r.fields.push({
                name: 'EventId', 
                relation: 'eventfair', 
                optionLabels: 'Name', 
                label: 'Fairs and Events', 
                addBlank: true, 
                editor: 'select', 
            });
            r.fields = Object.assign([], r.fields)
            return r;
        },

    }
}
</script>