<script>
const bookingButtonActions = importVueComp('components/booking', 'BookingButtonActions', 'custom');

export default {
    name: 'custom-booking-button-actions',
    mixins: [bookingButtonActions],
    components: {
        'booking-button-actions': bookingButtonActions
    },
    mounted () {
        window._booking = this.recordStore;
    },
    data () {
        const vamoosIcon = require('@/extra/custom/img/vamoos-icon.png');
        return {
            customButtons: [{
                click: this.openBreakdown,
                show: () => {
                    return this.recordStore.TemplateType=='NONE' && this.recordStore.id;
                },
                title: tr('Breakdown'),
                icon: 'receipt',
            },
            {
                click: this.openSyncOptions,
                show: () => {
                    return this.recordStore.bookingConfirmed;
                },
                title: 'Sincronizar con Vamoos',
                img: vamoosIcon,
                imgClass: 'vamoos-icon',
                disabledIf: () => {
                    return this.recordStore._modified;
                },
                smallIcon: 'lock',
                smallIconClass: 'vamoos-locked',
                smallIconIf: () => {
                    return this.recordStore.VamoosLocked;
                }
            }
            ]
        }
    },
    methods: {
        openBreakdown: function() {
            let route = this.$router.resolve({
                name: 'booking-breakdown',
                params: {
                    hash: this.recordStore.Hash,
                    id: this.recordStore.id,
                }
            })
            window.open(route.href, '_blank');
        },
        openSyncOptions () {
            let syncOptions = [];
            let canLockVamoos = tools.canAccess(api.currentUser, 'api','GET', 'can_lock_vamoos', false);
            if (!this.recordStore.VamoosLocked) syncOptions.push({value: 'Sync', label: 'Sincronizar'});
            if (canLockVamoos) syncOptions.push({value: 'Lock', label: 'Bloquear'});
            if (canLockVamoos && this.recordStore.VamoosLocked) syncOptions.push({value: 'Unlock', label: 'Desbloquear'});
            if (process.env.NODE_ENV == 'development') syncOptions.push({value: 'SyncTest', label: 'Sync Test'});
            let selectValue = {
                options: syncOptions,
                text: 'Elegir acción',
                defValue: null,
                save: (value) => {
                    if (value == 'Sync') this.syncVamoos();
                    if (value == 'Lock') this.lockVamoos();
                    if (value == 'Unlock') this.unlockVamoos();
                    if (value == 'SyncTest') this.syncVamoosTest();
                }

            }
            this.$root.$children[0].openSelectValue(selectValue);
        },
        async syncVamoos () {
            this.recordStore._sync_vamoos = true;
            await this.$parent.save();
            this.recordStore._sync_vamoos = false;
        },
        async lockVamoos () {
            this.recordStore.VamoosLocked = true;
            await this.$parent.save();
            this.customButtons = Object.assign([], this.customButtons);
        },
        async unlockVamoos () {
            this.recordStore.VamoosLocked = false;
            await this.$parent.save();
            this.customButtons = Object.assign([], this.customButtons);
        },
        async syncVamoosTest () {
            let data = await api.get('/api/vamoos/sync_test/' + this.recordStore.id);
            console.log(data);
        },
    },

}
</script>

<style>
.vamoos-icon {
    max-height: 18px;
    margin-bottom: 3px;
}

.vamoos-locked {
   color: red;
   position: relative;
   top: -5px;
   right: 12px;
   height: 20px !important;
   padding: 3px !important;
   margin-right: -17px;
}

</style>
