<template>
    <div class="col-12" v-if="recordStore.anyQuoteSelected && !recordStore.HideTotalPrice">
        <div class="box">
            <div class="box-header">
                <h3 v-if="recordStore.ShowPriceBy=='TOTAL_TRIP'">{{tr('Total Trip Price')}}</h3>
                <h3 v-else>{{tr('Price per Person')}}</h3>
            </div>
            <div class="col-12 py-3" v-for="(hotelCategory, hotelCategoryId) of hotelCategoriesNames" :key="hotelCategoryId">
                <h4 class="col-12" v-if="Object.keys(hotelCategoriesNames).length>0 && Object.keys(hotelCategoriesNames)[0]!='_no_hotel_category_'">
                    <span v-if="recordStore.Language.Code!='en'">{{tr('Accommodation')}}</span>&nbsp
                    <span> {{hotelCategory}}</span>&nbsp
                    <span v-if="recordStore.Language.Code=='en'">{{tr('Accommodation')}}</span>
                </h4>
                <div v-if="recordStore.ShowPriceBy=='TOTAL_TRIP'">
                  <div class="row py-0 d-flex align-items-center justify-content-center" v-for="(t, tId) of recordStore.bookingTotalsTrip" :key="tId">
                      <span class="price" v-if="recordStore.SumByType=='ONE_TOTAL'">
                          {{t.Price | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                      </span >
                      <span class="h5 mb-0 col-6" v-if="recordStore.SumByType=='QUOTE'">
                          {{t.PaxText}} {{t.Comment}}
                      </span>
                      <span class="price col-6" v-if="recordStore.SumByType=='QUOTE'">
                          {{t.Price | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                      </span>
                      <span class="price" v-if="recordStore.SumByType=='HOTEL_CATEGORY' && isHotelCategory(t, hotelCategoryId)">
                          {{t.Price | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                      </span>
                  </div>
                </div>
                <div v-else>
                  <div v-for="(t, tId) of recordStore.bookingTotals" class="row py-3 justify-content-center" :key="tId" v-if="isHotelCategory(t, hotelCategoryId)">
                      <div class="col-6 d-flex align-items-center justify-content-center">
                          <h4 v-if="t.Room">
                              {{tr(t.pType)}} -  <span v-if="getRoomTypeNames(t)">{{getRoomTypeNames(t)}}</span>
                              ({{tr('based on')}} {{t.pax}} pax)
                              <span v-if="t.Quote.Comment">{{t.Quote.Comment}}</span>
                          </h4>
                          <h4 v-else>{{tr(t.pType)}} <br>({{tr('based on')}} {{t.pax}} pax)
                              <p v-if="t.Quote.Comment">{{t.Quote.Comment}}</p>
                          </h4>
                      </div>
                      <div class="col-6" v-if="t.Quote.Price">
                        <div class="price" v-if="recordStore.QuoteBy=='QUANTITY' && t.Quote.Price && isHotelCategory(t, hotelCategoryId)">
                            {{getTotalInCurrency(t.Quote.Price/ t.pax) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                        </div>
                        <div class="price" v-else-if="t.Quote.Price && isHotelCategory(t, hotelCategoryId)">
                           {{getTotalInCurrency(t.Quote.Price) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                        </div>
                      </div>
                  </div>
                </div>
                <h5 v-if="finalTotalFlightsNotIncluded && recordStore.ShowPriceBy=='TOTAL_TRIP'" class="mt-4">
                    <span>
                        {{tr('Total Flights')}}
                    </span>
                    <span>
                        {{finalTotalFlightsNotIncluded | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                    </span>
                </h5>
                <div v-if="recordStore.ShowPriceBy!='TOTAL_TRIP'" class="mt-4">
                    <h5 v-for="(flight, tFlight) of recordStore.flightsNotIncluded" :key="tFlight">
                        <span v-if="flight.FlightType=='LOCAL'">
                            {{tr('Internal Flights')}} {{tr(flight.pType)}}
                            <p v-if="flight.Comment">{{flight.Comment}}</p>
                        </span>
                        <span v-if="flight.FlightType=='INTERNATIONAL'">
                            {{tr('International Flights')}} {{tr(flight.pType)}}
                            <p v-if="flight.Comment">{{flight.Comment}}</p>
                        </span>
                        <span>
                            {{getTotalInCurrency(flight.Price) | formatNumber({c: recordStore.PriceCurrency, s: 2, d: 0})}}
                        </span>
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
const itineraryPrices  = importVueComp('components/itinerary', 'ItineraryPrices', 'custom');
export default {
    name: 'custom-itinerary-prices',
    mixins: [itineraryPrices],
}
</script>


