<script>
const salesSummaryReport = importVueComp('components/reports', 'SalesSummaryReport', 'custom');
export default {
    name: 'custom-sales-summary-report',
    mixins: [salesSummaryReport],
    methods: {
        getData (self) {
            let r = salesSummaryReport.methods.getData(this);
            r.fields.push({
                name: 'EventId', 
                relation: 'eventfair', 
                optionLabels: 'Name', 
                label: 'Fairs and Events', 
                addBlank: true, 
                editor: 'select', 
            });
            r.fields = Object.assign([], r.fields)
            return r;
        },
    }
}
</script>