<template>
    <footer id="footer" class="footer">
      <div class="container">
        <div class="row py-1 d-flex justify-content-between justify-content-xs-center">
            <div class="col-md-4 col-xs-12 text-center">
              <img src="@/extra/custom/img/logo-itinerary.png" alt="">
            </div>
        <div class="col-md-4 col-xs-12 copyright text-center">  &copy; Copyright <strong><span>Desginer Trips</span></strong>. All Rights Reserved</div>
       </div>
      </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'custom-itinerary-footer',
}
</script>

