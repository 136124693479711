<template>
    <section id="faq" class="faq">
        <div class="container" v-if="!recordStore.DisableTerms">
            <div class="row">
                <div class="col-lg-12">
                    <div class="accordion accordion-flush" id="faqlist1">
                        <div class="accordion-item">
                            <h2 class="accordion-header cursor-pointer border-bottom">
                            <div class="accordion-button collapsed d-flex"  data-toggle="collapse" data-target="#faq-content-1">
                                <span>{{tr('Terms and Conditions')}}</span>
                            </div>
                            </h2>
                            <div id="faq-content-1" class="accordion-collapse collapse" data-parent="#faqlist1">
                                <div class="accordion-body">
                                    <div class="d-flex align-items-center mb-2">
                                        <i class="bi bi-check2"></i>
                                        <text-rows-style :text="getTermsAndConditions"></text-rows-style>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
const itineraryConditions  = importVueComp('components/itinerary', 'ItineraryConditions', 'custom');
export default {
    name: 'custom-itinerary-conditions',
    mixins: [itineraryConditions],
}
</script>