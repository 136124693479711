<template>
      <report-window
        v-if="ready"
        endpoint="/custom/reports/bookingtax"
        :fields="fields"
        :title="cardTitle || title"
        :headers="headers"
        :templates="templates"
        :perPage="(options && options.perPage)? options.perPage: 500"
        :hideFilters="options && options.hideFilters"
        :runMounted="options && options.runMounted"
        :cardView="options && options.cardView"
        :columnsClasses="columnsClasses"
        :notFilterable="options && options.notFilterable"
        :headerColumns="options? options.headerColumns: null"
        :columnClick="columnClick"
        :current.sync="current"
        :totalsOn="totalsOn"
        :fieldClasses="fieldClasses"
        ></report-window>
</template>

<script>
import ReportWindow from '@/components/tools/ReportWindow'
export default {
    name: 'booking-tax-report',
    props: ['options', 'cardTitle'],
    components: {
        ReportWindow,
    },
    mounted () {
        frontTools.setReportValues(this);
        this.ready = true;
    },
    data () {
        return {
            current: {},
            columnsClasses: {
                Base: 'text-right',
                Amount: 'text-right'
            },
            totalsOn: ['Base', 'Amount'],
            ready: false,
            fields: [
                {
                    name: 'FromDate', 
                    editor: 'date', 
                    label: 'From'
                },
                {
                    name: 'ToDate', 
                    editor: 'date', 
                    label: 'To'},
                {
                    name: 'BookingId', 
                    editor: 'number', 
                    label: 'File', 
                    //required: true 
                },

            ],
            title: 'Impuestos por File',
            headers: {
              BookingId: 'File',
              Tax: 'Impuesto',
              Base: 'Base Imponible',
              Amount: 'Valor',
            },
            templates: [
                {name: 'Base', callback: (row) => {                        
                        return tools.toNumber(row['Base'], 2);
                    }
                }, 
                {name: 'Amount', callback: (row) => {                        
                        return tools.toNumber(row['Amount'], 2);
                    }
                }
            ],
            fieldClasses: {
                BookingId: (row) => {
                    return 'cursor-pointer-report';
                },
            },
            columnClick: {
                BookingId: this.showBooking,
            },
      }
    },
    methods: {
        showBooking (row) {
            let route = this.$router.resolve({ name: 'my-booking', params: {id: row.BookingId.toString(), TemplateType: 'NONE'} })
            window.open(route.href, '_blank');
        },
    }
}
</script>

