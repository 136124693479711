<template>
    <div>
        <booking-quote-config></booking-quote-config>
        <div class="form-row col-md-8 form-material">
            <div class="form-row col-md-9">
                <div class="col-md-4 px-2">
                    <number-input
                    class="mt-0"
                    :v.sync="recordStore.AgencyCommission"
                        label="Comisión Agencia"
                        @change="changeAgencyCommission"
                    ></number-input>

                </div>
                <div class="col-md-4 px-2">
                    <number-input
                        class="mt-0"
                        :v.sync="recordStore.PaxTax"
                        label="Gastos Pasajero"
                        @change="changeAgencyCommission"
                    ></number-input>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteConfig = importVueComp('components/booking', 'BookingQuoteConfig', 'custom');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'custom-booking-quote-config',
    mixins: [bookingQuoteConfig],
    data () {
        return {
        }
    },
    components: {
        'booking-quote-config': bookingQuoteConfig,
    },
    methods: {
        changeAgencyCommission () {
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
    }

}
</script>

