<script>
    const abmBooking = importVueComp('components/tools', 'abmBooking', 'custom')
    export default {
        name: 'custom-abm-booking',
        mixins: [abmBooking],
        methods: {
            getColumns () {
                let columns = Object.assign([], this.abm.columnsNames);
                if (this.options.fieldsAbmList) {
                    columns = Object.assign([], this.options.fieldsAbmList);
                }
                if (this.$router.currentRoute.name == "abm-sales") {
                    columns.splice(columns.indexOf('StartDate') + 1, 0, 'EndDate');
                }
                this.columnsNames = columns;
            }
        },
    }
</script>