<script>
import Vue from 'vue';
import { mapState } from 'vuex';

let bookingCoverMainExtras = Vue.component('booking-cover-main-extras', {
    props: [],
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
    },
    template: `
        <div class="form-row col-md-12 px-0" v-if="recordStore.SalesOrigin && recordStore.SalesOrigin.AllowEventFair">
            <div class="col-md-2 px-1">
                <input-field
                    :defValue.sync="recordStore.EventFairId"
                    :field="recordStore.fieldsObject.EventFairId"
                    class="col-md-3 px-3"
                ></input-field>
            </div>    
        </div>    
    `,
})


const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain', 'custom');
export default {
    name: 'custom-booking-cover-main',
    mixins: [bookingCoverMain],
    mounted () {
        this.extraComp.push({
            component: bookingCoverMainExtras,
            ref: 'bookingCoverMainExtras',
            change: () => {},
        })
    },

}
</script>

<style scoped>
</style>

