<template>
    <div v-if="showService">
        <b v-if="s.Optional">
            {{titleOptional}} <i class="text-danger">{{tr('Optional Service')}} </i>
        </b>
        <b v-else-if="title">
            {{title}}
        </b>
        <text-rows-style
            class="italic text-justify mb-0"
            :text="text"
            v-if="s && ifDescription && !edit"
            @edit="setEdit"
        ></text-rows-style>
        <text-area-edit-style
            v-else-if="s && edit"
            :text.sync="s.Description"
         ></text-area-edit-style>
        <p v-if="s.Service.ServiceType && !s.Service.NotPrice && ifText && (readMore || text.length < limit)"  class="mb-0">
            <i><b>{{tr('Type of service')}}:</b> {{tr(s.Service.ServiceType.Name)}}</i>
        </p>
        <p v-if="s.EntranceIncluded">
            <strong><i>{{tr('_entrance_includes')}}</i></strong>
        </p>
        <p v-if="recordStore.ShowServiceLanguage && s.Service.languageOffered && show">
            <strong>
                <i>{{tr('Languages')}}: {{getLanguageOffered(s.Service)}}</i>
            </strong>
        </p>
        <p v-if="s.Service.Guide">
            <strong>
                <font-awesome-icon :icon="['fas', 'user']" /> <i> {{tr('Guide Included')}}</i> 
            </strong>
        </p>
        <p v-if="s.Service.Transfer">
            <strong>
                <font-awesome-icon :icon="['fas', 'car']" /> <i> {{tr('Transfer Included')}}</i> 
            </strong>
        </p>
        <br>
    </div>
</template>

<script>
const itineraryBodyService = importVueComp('components/itinerary', 'ItineraryBodyService', 'custom');
import { mapState } from "vuex";

export default {
    name: 'custom-itinerary-body-service',
    mixins: [itineraryBodyService],
    computed: {
        languageSupplement () {
            for (let s of this.day.BookingDayServices) {
                if (s.Service && s.Service.ServiceClass && s.Service.ServiceClass.LanguageSupplement) {
                    return s.Service.Name;
                }
            }
        }
    }
}
</script>