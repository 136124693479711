<script>
    const receiptListReport = importVueComp('components/reports', 'ReceiptListReport', 'custom');
    
    export default {
        name: 'custom-receipt-list-report',
        mixins: [receiptListReport],
        mounted () {
            let agencyIdIndex = _.findIndex(this.fields, (r) => r.name == 'AgencyId')
            this.fields.splice(agencyIdIndex+1, 0, 
            { 
                name: 'SalesOriginId', 
                label: 'SalesOrigin',
                editor: 'select', 
                relation: 'salesorigin',
                optionLabels: 'Name',
                addBlank: true,
                }
            );
        },
    }
</script>

