<template>
    <div v-if="ready" id="confirmation">
        <div class="button-fixed not-print">
            <div class="col-12">
                <button type="button" class="btn btn-primary action-button mr-1"
                    @click="print()">
                    <font-awesome-icon icon="print"/>
                </button>
            </div>
        </div>
    <div class="container">

    <table class="w-100">
    

    <tbody>
      <tr>
        <td>
        <div class="container container-body page">
            <div class="row">
                <div class="col-12 text-center">
                    <h2>
                        <async-img width="400" id="logo" path="extra/custom/img" img="logo-full.png"></async-img>
                    </h2>
                    <br>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    <h4>
                        <strong>{{tr('Ref.')}} {{recordStore.PaxName}}</strong>
                    </h4>
                </div>
            </div>
            <div class="row mb-2" v-if="recordStore.CustomerType && recordStore.CustomerType.Agency && recordStore.Agency">
                <div class="col-12">
                    <h4>
                        <strong>{{recordStore.Agency.Name}}</strong>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <strong>{{tr('Date')}}:</strong> {{getDate(new Date())}}
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <strong>{{tr('Names')}}:</strong> {{recordStore.PaxName}}
                </div>
            </div>
            <!--div class="row">
                <div class="col-12">
                    <strong>{{tr('Flight Schedule')}}:</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-for="f of getFlightsList">
                    {{f}}
                </div>
                <div class="col-12" v-for="f of getFlightsData">
                    {{f}}
                </div>
            </div-->
            <div class="row">
                <div class="col-12">
                    <strong>{{tr('Program')}}:</strong> {{ this.recordStore.Title }} ({{ this.recordStore.id }})
                </div>
            </div>
            <br>
            <table  class="w-100">
            <thead>
            <tr class="small">
                <td class="col-date text-center">
                    {{tr('Date')}}
                </td>
                <td class="col-location">
                    {{tr('Location')}}
                </td>
                <td class="col-description">
                    {{tr('DESCRIPTION')}}
                </td>
                <!--td class="col-description">
                    RES.NR.
                </td-->
                <!--td class="text-right col-price">
                    {{tr('COST')}}
                </td-->
                <td class="text-right col-price">
                    {{tr('TOTAL')}}
                </td>
                <hr class="my-2 hr-strong mb-2">
            </tr>
            </thead>
            <tbody>
            <tr class="small border-bottom" v-for="row of sortedServicesAnHotels">
                <td class="col-date text-center pr-3">
                    <span v-if="row.type=='Hotel'" class="no-wrap">
                        Check-in: {{getDate(row.date)}} <br>
                    </span>
                    <span v-if="row.type=='Hotel'" class="no-wrap">
                        Check-out: {{getDate(row.checkOut)}}
                    </span>
                    <span v-else>
                        {{getDate(row.date)}} 
                    </span>
                </td>
                <td class="col-location">
                    {{row.city}}
                </td>
                <td class="col-description">
                    {{row.name}}
                </td>
                <td class="text-right col-price">
                    <span v-if="row.price">{{row.price.toFixed(2)}}  </span>
                </td>
            </tr>
            </tbody>
            <tfoot>
                <tr class="small border-bottom font-weight-bold">
                    <td class="text-description" colspan="3">
                        TOTAL NET SVCS & HOTELS
                    </td>
                    <td class="text-right col-price" v-if="finalTotal">
                        {{stdFinalTotal.toFixed(2) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}
                    </td>
                </tr>
                <tr class="small border-bottom font-weight-bold" v-if="recordStore.AgencyCommission">
                    <td class="text-description" colspan="2">
                        TOTAL SVCS GROSS {{recordStore.AgencyCommission}}% INCL
                    </td>
                    <td class="text-right col-price">
                    </td>
                    <td class="text-right col-price" v-if="finalTotal">
                        {{(finalTotal * ( 1 - recordStore.PaxTax/ 100)).toFixed(2) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}
                    </td>
                </tr>
                <tr class="small border-bottom font-weight-bold" v-if="recordStore.PaxTax">
                    <td class="text-description" colspan="2">
                        TOTAL PRICE TAX {{recordStore.PaxTax}}% INCL
                    </td>
                    <td class="text-right col-price">
                    </td>
                    <td class="text-right col-price" v-if="finalTotal">
                        {{finalTotal.toFixed(2) | formatNumber({c: recordStore.PriceCurrency, s: 1})}}
                    </td>
                </tr>

            </tfoot>
            </table>
        </div>
        </td>
      </tr>
    </tbody>

    </table>
    </div>

        <footer class="not-print mb-5">
            <div class="container">
                <div class="row">
                </div>
            </div>
        </footer>

        <div class="divFooter page-footer">
            <div class="container">
                <div class="row">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@/extra/custom/css/confirmation.scss';
import { mapState } from 'vuex';

export default {
    name: 'booking-confirmation',
    props: ['id', 'hash'],
    data () {
        return {
            suppliers: false,
            ready: false,
        }
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        langCode () {
            return 'en';
        },
        finalTotal () {
            if (this.recordStore.finalTotal) return this.getTotalInCurrency(this.recordStore.finalTotal);
            return 0;
        },
        stdFinalTotal () {
            if (this.recordStore.stdFinalTotal) return this.getTotalInCurrency(this.recordStore.stdFinalTotal);
            return 0;
        },
        finalCost () {
            return this.recordStore.finalCost;
        },
        sortedServicesAnHotels () {
            let total = 0;
            let totalsByHotelByRoom = this.recordStore.totalsByHotelByRoom;
            let res = [];
            let groupByServices = {};
            for (let day of this.recordStore.BookingDays) {
                for (let service of day.BookingDayServices) {
                    if (service.Optional) continue;
                    if (service.GroupByServiceId) {
                        if (!groupByServices[service.GroupByServiceId]) {
                            groupByServices[service.GroupByServiceId] = [];
                        }
                        groupByServices[service.GroupByServiceId].push(service);
                        continue;
                    }
                    if (service.Service && service.Service.NotPrice) continue;
                    if (service.Quote && !service.Quote.Include) continue;
                    let row = {
                        ServiceId: service.ServiceId,
                        date: day.TransDate,
                        type: 'Service',
                        resNr: service.ReservationNumber,
                        pickupText: service.PickupText ? service.PickupText.Text : null,
                        pickupTime: service.PickupInfo
                    }
                    if (service.Service && service.Service.City && service.Service.City.Name) {
                        row.city = service.Service.City.Name
                    }
                    if (!row.city && service.City && service.City.Name) {
                        row.city = service.City.Name
                    }
                    row.name = null;
                    if (service.title) {
                        row.name = service.title;
                    }
                    if (!row.name && service.ServiceId && service.Service) {
                        let t = _.find(service.Service.ServiceDescriptions, (c) => c.LanguageId==this.recordStore.LanguageId);
                        if (t) row.name = t.Title;
                    }
                    if (!row.name && service.Name) {
                        row.name = service.Name;
                    }
                    if (!row.name && service.Service && service.Service.Name) {
                        row.name = service.Service.Name;
                    }
                    if (service.Service && service.Service.Supplier) {
                        row.supplier = service.Service.Supplier.Name;
                    }
                    if (service.Supplier && service.Supplier.Name) {
                        row.supplier = service.Supplier.Name;
                    }
                    if (!row.name) continue;
                    row.qty = 0;
                    row.price = service.serviceTotal.Price;
                    row.show = true;
                    row.price = this.getTotalInCurrency(row.price);
                    total += row.price;
                    res.push(row);
                }
                for (let hotel of day.BookingDayHotels) {
                    for (let room of hotel.BookingDayRooms) {
                        let data = [room.Hotel.Name, room.Category.Name];
                        if (room.Category.Comment) {
                            data.push(room.Category.Comment)
                        }
                        let checkout = moment(day.TransDate).add(hotel.Days, 'days');
                        data.push(room.Room.Name)
                        let row = {
                            date: day.TransDate,
                            checkOut: checkout,
                            type: 'Hotel',
                            city: room.Hotel.City? room.Hotel.City.Name: '',
                            days: hotel.Days,
                            name: data.join(', '),
                            resNr: room.ReservationNumber,
                            supplier: room.Hotel.Name,
                        }
                        row.qty = 0;
                        if (!totalsByHotelByRoom[room.Hotel.id]) continue;
                        if (!totalsByHotelByRoom[room.Hotel.id][room.id]) continue;
                        row.price = totalsByHotelByRoom[room.Hotel.id][room.id].Price;
                        row.price = this.getTotalInCurrency(row.price);
                        total += row.price;
                        res.push(row);
                    }
                }
            }
            for (let id in groupByServices) {
                let services = groupByServices[id];
                let row = res.find(r => r.ServiceId==id);
                if (row) {
                    for (let service of services) {
                        row.price += this.getTotalInCurrency(service.serviceTotal.Price);
                    }
                }
            }
            let included;
            let found;
            for (let flight of this.recordStore.BookingFlights) {
                found = true;
                if (flight.QuoteType=='INCLUDED') included = true;
            }
            if (found) {
                let price = this.getTotalInCurrency(this.recordStore.finalTotalFlights.Price);
                total += price;
                let row = {
                    type: 'Flight',
                    city: '',
                    days: '',
                    name: 'Flights schedule in the proposal ' + (included? '(INCLUDED in final price)': '(NOT INCLUDED in final price)'),
                    price,
                }
                row.qty = '';
                res.push(row);
            }
            return res;
        },
        getFlightsList () {
            let res = [];
            if (!this.recordStore.BookingFlights) return res;
            for (let f of this.recordStore.BookingFlights) {
                if (f.Output) {
                    let rows = f.Output.split('\n');
                    for (let r of rows){
                        res.push(r);
                    }
                }
            }
            return res;
        },
        getFlightsData () {
            if (!this.recordStore.BookingFormFlightsData) return [];
            return this.recordStore.BookingFormFlightsData.split('\n');
        }
    },
    async mounted (){
        api.setProcessing(true)
        let model = await api.importMixinModule('booking', 'model');
        let r = await api.get('/public/api/booking_prices/' + this.hash + '/' + this.id);
        if (r) {
            r._public = true;
            let record = await new model({record: r});
            this.$store.commit('initRecord', {record});
        }
        this.ready = true;
        api.setProcessing(false)
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getDate (d) {
            if (!d) return '';
            if (!this.recordStore.Language) return;
            let r = moment(d).local().locale(this.recordStore.Language.Code).format('DD MMM YY')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        print () {
            print()
        },
        getTotalInCurrency (price) {
            let r = travelTools.currencyConvert(this.recordStore.CurrencyId, this.recordStore.PriceCurrencyId,
                price, this.recordStore.CurrencyRates);
            return r;
        },
    }
}
</script>

<style scoped>
.no-wrap {
    text-wrap: nowrap !important;
}
</style>