<template>
    <div id="voucher-dstrip">

        <div class="container container-body page">
            <!-- BANNER -->
            <div class="row">
                <div class="col-12 text-center">                                        
                    <div v-if="imageAgency">
                        <async-img :url="imageAgency" class="" width="200"></async-img>
                    </div>
                    <div v-else >
                        <async-img class="img-fluid" id="dstrip-logo" path="extra/custom/img" img="logo-full.png"></async-img>
                    </div>
                    <br>
                </div>
            </div>
            <!-- ENCABEZADO VOUCHER -->
            <div class="row mb-2" v-if="recordStore.CustomerType && recordStore.CustomerType.Agency && recordStore.Agency">
                <div class="col-12">
                    <h4>
                        <strong>{{recordStore.Agency.Name}}</strong>
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <strong>{{tr('Names')}}:</strong> {{recordStore.PaxName}}
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <strong>{{tr('Booking Confirmation number')}}:</strong> {{recordStore.id}}
                </div>
            </div>
            <!-- VUELOS -->
            <div class="row">
                <div class="col-12">
                    <strong>{{tr('Flight Schedule')}}:</strong>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-for="f of getFlightsList">
                    {{f}}
                </div>
                <div class="col-12" v-for="f of getFlightsData">
                    {{f}}
                </div>
            </div>
            <div class="row mt-3 mb-3">
                <div class="col-12">
                    <strong v-if="bookingConfirmed">{{tr('Confirmed Program')}}:</strong>
                    <strong v-else>{{tr('Program')}}:</strong>
                </div>
            </div>

            <table>
                <!-- SERVICE TITLES -->   
                <thead>
                    <tr class="border-bottom">                        
                        <td class="col-description text-start col-6">
                            <strong>{{tr('Service')}}</strong>
                        </td>
                        <td class="text-center col-1">
                            <strong>{{tr('Date')}}</strong>
                        </td>
                        <td class="col-pickup text-center col-3">
                            <strong>{{tr('Pick-up & Details')}}</strong>
                        </td>
                        <td class="col-supplier col-2 text-start">
                            <strong>{{tr('Provided by')}}</strong>
                        </td>
                    </tr>
                </thead>                       
                <!-- SERVICE CONTENT -->  
                <tbody>
                    <tr class="small border-bottom" v-for="row of sortedServicesAnHotels">
                        <td class="col-description">
                            <span v-if="row.title"> {{row.title}}</span>
                            <span v-else>{{row.name}}</span>                                                        
                        </td>
                        <td class="col-date col-date text-center">
                            <span v-if="!row.checkin">{{getDate(row.date)}}  </span>
                            <span v-if="row.checkOut && !row.checkin"> / {{getDate(row.checkOut)}} </span>
                        </td>
                        <td class="text-center">
                            <span v-if="row.pickup">{{row.pickup}}</span>
                            <span v-if="row.type=='Hotel' && !row.checkin">Check in: {{row.checkInTime}} /<br> Check out: {{row.checkOutTime}}</span>
                            <span v-if="row.reservationNumber"><br> {{tr('Confirmation code')}}: {{ row.reservationNumber }} </span>
                        </td>                                                              
                        <td class="col-supplier">
                            {{row.supplier}}
                        </td>
                    </tr>
                </tbody>
                <!-- TABLE FOOT --> 
                <tfoot>
                    <tr class="blank-row">
                    </tr>
                </tfoot>
            </table>
            <br>
            
            <!-- USEFUL INFORMATION -->

            <div class="info-box not-break-inside">
                <h6 class="info-box-title"  >{{tr('Useful Information')}}</h6>
                <div class="col-6 child" >
                    <br>
                    <span class="info-box-heading">{{tr('Contacts')}}...</span>
                    <ul class="info-box-content">
                        <li class="info-box-row">
                            <div>Buenos Aires - <i>Designer Trips</i></div>
                            <div>Tel: (+54) 9 11 3081 8348</div>
                        </li>
                    </ul>
                    <ul class="info-box-content">
                        <li v-for="supplier of getSuppliers" class="info-box-row">
                            <div>{{supplier.City}} - <i>{{supplier.Supplier.Name}}</i></div>
                            <div>Tel: {{supplier.Supplier.Phone}}</div>
                        </li>
                    </ul>
                </div>
                <div class="col-6 child" >
                    <br>
                    <span class="info-box-heading">{{tr('Accommodation')}}...</span>
                    <ul class="info-box-content">
                        <li v-for="hotel of getHotels" class="info-box-row">
                            <div>{{hotel.City}} - <i>{{hotel.Hotel.Name}}</i></div>
                            <div>{{tr('Address')}}: {{hotel.Hotel.Address}}</div>
                            <div>{{tr('Phone Number')}}: {{hotel.Hotel.Phone}}</div>
                        </li>
                    </ul>
                </div>
            </div>
            <br>

            <div class="info-box-notes">
                <p>*In order to provide you with the highest quality services, we have established partnerships with the
                    best companies in every destination.
                    They will provide you with all the information you need and confirm your pick-up times once you
                    arrive.
                </p>
                <p>*It ́s important to know that for the activities that are shared the pick-up times are estimative and you have to consider a 30 minutes waiting time from the indicated time.
                    In the event of an emergency during your trip, you can reach us at the following number: (+54) 9 11 3081 8348
                    We highly recommend using WhatsApp for easy communication.
                    Please note that this number is only for urgent situations, such as a driver not appearing, a delayed airport transfer, or last-minute changes.
                </p>
            </div>

            <!--
            <div class="col-12 emergency-contact">
                <h6>{{tr('Emergency contacts')}}</h6>
                <div class="info-box-body">
                    <text-rows-style tag="p" :text="recordStore.EmergencyContacts"></text-rows-style>
                </div>
            </div>
            -->

        </div>        
    </div>
</template>

<script>
import { mapState } from 'vuex';
import '@/extra/custom/css/voucher.scss';
export default {
    name: 'custom-vouchers-preview',
    props: ['edit'],
    components: {
    },
    data () {
        return {
            confirmationURL: null,
            prices: false,
            ready: false,
        }
    },
    async mounted () {
        this.ready = true;
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            processing: state => state.main.processing,
        }),
        langCode () {
            if (this.recordStore && this.recordStore.Language) return this.recordStore.Language.Code;
            return false;
        },
        companyCode () {
            if (this.recordStore) {
                return this.recordStore.companyCode;
            }
        },
        quotesSelected () {
            return this.recordStore.quotesSelected;
        },
        finalTotalWithFlights () {
            return this.recordStore.finalTotalWithFlights;
        },
        finalTotal () {
            return this.recordStore.finalTotal;
        },
        bookingConfirmed () {
            return this.recordStore.bookingConfirmed;
        },
        sortedServicesAnHotels () {
            let res = [];
            for (let day of this.recordStore.BookingDays) {
                for (let service of day.BookingDayServices) {
                    if (service.VoucherDisabled) continue;
                    if (service.Optional) continue;
                    if (service.Service && service.Service.NotPrice) continue;
                    if (service.Quote && !service.Quote.Include) continue;
                    let row = {
                        date: day.TransDate,
                        type: 'Service',
                        pickup: service.PickupInfo,
                        title: service.title
                    }                  
                    if (service.title) {
                        row.title = service.title;
                    }
                    if (service.Name) {
                        row.name = service.Name;
                    }
                    if (!row.name && service.Service && service.Service.Name) {
                        row.name = service.Service.Name;
                    }
                    if (service.Service && service.Service.Supplier) {
                        row.supplier = service.Service.Supplier.Name;
                    }
                    if (service.Supplier && service.Supplier.Name) {
                        row.supplier = service.Supplier.Name;
                    }
                    if (service.CityId == 1) {
                        row.supplier = "Designer Trip"
                    }
                    if (service.Service.CityId == 1) {
                        row.supplier = "Designer Trip"
                    }
                    if (!row.name) continue;
                    row.qty = 0;
                    row.price = 0;
                    row.show = false;
                    if (service.Quote && service.Quote.QuoteList) {
                        for (let baseId in this.recordStore.BaseQuotes.bases) {
                            if (!service.Quote.QuoteList[baseId]) continue;
                            if (this.quotesSelected[baseId]) {
                                row.show = true;
                            }
                        }
                    }
                    if (row.show) res.push(row);
                }
                for (let hotel of day.BookingDayHotels) {
                    for (let room of hotel.BookingDayRooms) {
                        let data = [room.Hotel.Name, room.Category.Name];
                        if (room.Category.Comment) {
                            data.push(room.Category.Comment)
                        }                                                                  
                        let checkout = moment(day.TransDate).add(hotel.Days, 'days');
                        data.push(room.Room.Name)
                        let checkin = room.Room? room.Room.Checkin: false;
                        let row = {
                            date: day.TransDate,
                            type: 'Hotel',
                            city: room.Hotel.City? room.Hotel.City.Name: '',
                            checkInTime: room.Hotel.Checkin,
                            checkOut: checkout,
                            checkOutTime: room.Hotel.Checkout,
                            name: data.join(', '),
                            supplier: null,
                            reservationNumber: room.ReservationNumber,
                            checkin,
                        };
                        row.qty = 0;
                        row.price = 0;
                        for (let baseId in this.recordStore.BaseQuotes.bases) {
                            if (room.QuoteList[baseId]) {
                                if (!this.quotesSelected[baseId]) continue;
                                if (!room.QuoteList[baseId].Include) continue;
                                row.price += room.QuoteList[baseId].TotalPrice;
                                if (room.QuoteList[baseId].Rooms) {
                                    row.qty = room.QuoteList[baseId].Rooms;
                                } else {
                                    row.qty = room.Rooms;
                                }
                            }
                        }
                        if (row.qty > 0) res.push(row);
                    }
                }
            }
            let included;
            let found;
            for (let flight of this.recordStore.BookingFlights) {
                found = true;
                if (flight.QuoteType=='INCLUDED') included = true;
            }
            if (found) {
                let row = {
                    type: 'Flight',
                    city: '',
                    days: '',
                    name: 'Flights schedule in the proposal ' + (included? '(INCLUDED in final price)': '(NOT INCLUDED in final price)'),
                }
                row.qty = '';
                res.push(row);
            }
            return res;
        },
        getSuppliers () {
            let res = {}
            if (this.recordStore && this.recordStore.BookingDays) {
                for (let day of this.recordStore.BookingDays) {
                    for (let service of day.BookingDayServices) {
                        if (service.Status == 'CANCELED') continue;   
                        if (service.CityId == 1) continue;
                        if (service.CityId == 2) continue;
                        if (service.Supplier && service.Supplier.id) {
                            if (!res[service.Supplier.id]) {
                                res[service.Supplier.id] = {Supplier: service.Supplier, City: service.City.Name, CityId: service.CityId};
                            }
                        }                  
                    }
                }
            }
            return res;
        },        
        getHotels () {
            let res = {};
            for (let day of this.recordStore.BookingDays) {
                if (day.BookingDayHotels[0]) {
                    for (let room of day.BookingDayHotels[0].BookingDayRooms) {
                        //let room = day.BookingDayHotels[0].BookingDayRooms[i];
                        if (!room.Hotel) continue;
                        if (!room.Hotel.id) continue;
                        if (room.Status == 'CANCELED') continue;
                        if (!room.quoteIncluded) continue;
                        if (!res[room.Hotel.id]) {
                            res[room.Hotel.id] = {Hotel: room.Hotel, City: room.Hotel.City.Name }
                        }
                    }
                }
            }
            return res;
        },
        getFlightsList () {
            let res = [];
            if (!this.recordStore.BookingFlights) return res;
            for (let f of this.recordStore.BookingFlights) {
                if (f.Output) {
                    let rows = f.Output.split('\n');
                    for (let r of rows){
                        res.push(r);
                    }
                }
            }
            return res;
        },
        getFlightsData () {
            if (!this.recordStore.BookingFormFlightsData) return [];
            return this.recordStore.BookingFormFlightsData.split('\n');
        },
        imageAgency () {
            if (this.recordStore.Agency && this.recordStore.Agency.Image) {
                return this.recordStore.Agency.Image.url;
            }
        },
    },
    methods: {
        tr (t) {
            return tr(t, this.langCode);
        },
        getDate (d) {
            if (!d) return '';
            if (!this.recordStore.Language) return;
            let r = moment(d, 'YYYY-MM-DD').local().locale(this.recordStore.Language.Code).format('DD MMM')
            return r.charAt(0).toUpperCase() + r.slice(1)
        },
        print () {
            print()
        },
            }
}
</script>
