const modules = vueTools.importModule('modules', 'tools', 'custom').default;

 modules.settingsMenu.endpoints.push('bookingsetting')

// Módulo Opciones
modules.settingsMenu.endpoints.push('eventfair')

if (modules.adminMenu) {
    modules.adminMenu.endpoints.push({endpoint: 'report', label: 'Impuestos por file', componentName: 'BookingTaxReport', report: 'bookingtax'});
}

export default modules;
