
<script>
import '@/extra/custom/css/r-style.scss';
const reservationForm = importVueComp('components/reservationform', 'ReservationForm', 'custom');
export default {
    name: 'custom-reservation-form',
    mixins: [reservationForm],
    data () {
        return {
            accept: true,
        }
    },
    mounted () {
        this.accept = true;
    },
}
</script>

