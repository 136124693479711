
<script>
const paymentListReport = importVueComp('components/reports', 'PaymentListReport', 'custom');
export default {
    name: 'custom-payment-list-report',
    mixins: [paymentListReport],
    methods: {
        getData () {
            let r = paymentListReport.methods.getData(this);
            let bookingIndex = r.headerColumns.indexOf('BookingId');
            let index = r.headerColumns.indexOf('CurrencyRate');
            let newIndex = r.headerColumns.indexOf('PaxName');
            r.headerColumns.splice(newIndex + 1, 0, 'CompanyName');
            r.headerColumns.splice(index + 1, 0, 'MEPCurrencyRate');
            r.headerColumns.splice(bookingIndex + 2, 1);
            r.headers.MEPCurrencyRate = 'Cotización MEP';
            r.slotsFields.push({name: 'MEPCurrencyRate', editor: 'number', decimal: 2, classes: 'mb-0'})
            return r;
        }
    }
}
</script>

